import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

const appRoutes: Routes = [
    {path: '', loadChildren: './home-page/home-page.module#HomePageModule'},
    {path: 'about-us', loadChildren: './about-page/about-page.module#AboutPageModule'},
    {path: 'products', loadChildren: './products-page/products-page.module#ProductsPageModule'},
    {path: 'product/:id', loadChildren: './details-page/details-page.module#DetailsPageModule'},
    {path: 'not-found', loadChildren: './page-not-found/page-not-found.module#PageNotFoundModule'},
    {path: '**', redirectTo: '/not-found'},
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, {preloadingStrategy: PreloadAllModules})],
    exports: [RouterModule]
})

export class AppRoutingModule {

}
