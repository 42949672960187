import {Products} from './products-list.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/index';
import {map} from 'rxjs/internal/operators';

@Injectable({providedIn: 'root'})
export class ProductsService {
    products: Promise<Products[]> = null;

    constructor(private http: HttpClient) {}

    public languageCode = 'en';

    getProducts(): Promise<Products[]> {
        if (!this.products) {
            return this.http.get<Products[]>('../../assets/products.json').pipe(map((products: Products[]) => {
                this.products = Promise.resolve(products);

                // console.log('only one time');
                return products;
            })).toPromise();
        }
        return this.products;
    }

    // getProducts(): Observable<Products[]> {
    //     return this.http.get<Products[]>('assets/products.json');
    // }

    // get products() {
    //     if (!this.getProdArr) {
    //         this.getProdArr = [];
    //         this.getProducts().subscribe((products: Products[]) => {
    //             this.getProdArr = products;
    //         });
    //     }
    //     return this.getProdArr;
    // }

    changeLanguage(lang: string) {
        this.languageCode = lang;
    }
}
