import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';
import { HttpClientModule } from '@angular/common/http';
import { ProductsService } from './shared/products.service';
import {NgxPaginationModule} from 'ngx-pagination';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        NgxPaginationModule,
        AppRoutingModule,
        HttpClientModule
    ],
    providers: [ProductsService],
    bootstrap: [AppComponent]
})
export class AppModule { }
